<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list color="black" shaped>
      <v-list-item
        v-for="(name, i) in items"
        :key="name"
        color="primary"
        v-on:click="$vuetify.goTo(nameItem[i])"
      >
        <v-list-item-content>
          <v-list-item-title v-text="name" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "CommerciaDrawer",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    nameItem: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <v-main class="pa-0">
    <CommercialAppBar />
    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>
    <CommercialFooter />
  </v-main>
</template>

<script>
import CommercialAppBar from "./CommercialAppBar.vue";
import CommercialFooter from "./CommercialFooter.vue";
export default {
  name: "CommercialMain",
  components: {
    CommercialAppBar,
    CommercialFooter,
  },

  props: {
    id: {
      type: String,
      default: "view",
    },
  },

  data: () => ({ sections: [] }),
};
</script>

<style lang="scss" scoped>
$headings: (
  "h4": (
    "letter-spacing": 0.1875rem,
    "weight": 900,
  ),
  "h5": (
    "letter-spacing": 0.1875rem,
    "weight": 700,
  ),
  "h6": (
    "letter-spacing": 0.1875rem,
    "size": 1rem,
    "weight": 700,
  ),
  "subtitle-1": (
    "letter-spacing": 0.1875rem,
  ),
  "subtitle-2": (
    "letter-spacing": 0.1875rem,
  ),
  "body-1": (
    "size": 1rem,
  ),
);
</style>
